import video1 from "../../assets/videos/Insta-timelapse-1.mp4";
import video2 from "../../assets/videos/Insta-timelapse-2.mp4";
import video3 from "../../assets/videos/Insta-timelapse-3.mp4";
import video4 from "../../assets/videos/Insta-timelapse-4.mp4";
import instagramImage1 from "../../assets/images/Insta-image-1.jpeg";
import instagramImage2 from "../../assets/images/Insta-image-2.jpeg";
import instagramImage3 from "../../assets/images/Insta-image-3.jpeg";
import instagramImage4 from "../../assets/images/Insta-image-4.jpeg";

const instagramPreviews = [
    {
        type: "video",
        src: video3,
    },
    {
        type: "image",
        src: instagramImage1,
    },
    {
        type: "video",
        src: video2,
    },
    {
        type: "image",
        src: instagramImage4,
    },
    {
        type: "image",
        src: instagramImage3,
    },
    {
        type: "video",
        src: video4,
    },
    {
        type: "image",
        src: instagramImage2,
    },
    {
        type: "video",
        src: video1,
    },
];

export default instagramPreviews;
