import React from "react";
import floorRightHalf from "../../assets/images/Floor-right.png";
import {
    Slide,
    TextWrapper,
    Root,
    Content,
    FaceBackgroundImage,
    Text,
    Link,
} from "./Etsy.styles.jsx";

function Etsy() {
    const renderContent = () => {
        const EtsyUrl = process.env.REACT_APP_ETSY_LINK;

        return (
            <Slide>
                <TextWrapper>
                    <Text>Webshop</Text>
                    <Link href={EtsyUrl} target="_blank">
                        ArtInkPrints
                    </Link>
                </TextWrapper>
            </Slide>
        );
    };

    return (
        <Root>
            <FaceBackgroundImage src={floorRightHalf} />
            <Content>{renderContent()}</Content>
        </Root>
    );
}

export default Etsy;
