import styled from "@emotion/styled";
import muiTheme from "../../core/theme/muiTheme";

export const Root = styled.div`
    position: relative;
`;

export const Content = styled.div`
    height: 100vh;
    z-index: 3;

    @media only screen and (max-width: 1100px) {
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media only screen and (max-width: 600px) {
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
    }
`;

export const Slide = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    z-index: 10;
    height: 100vh;

    @media only screen and (max-width: 600px) {
        width: 100vw;
    }
`;

export const PreviewContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
    grid-gap: 1rem;
    padding-top: 24px;

    & img {
        height: 240px;
        width: 240px;
    }

    & video {
        height: 240px;
        width: 240px;

        &::-webkit-media-controls-fullscreen-button {
            display: none !important;
        }
    }

    @media only screen and (max-width: 1100px) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-rows: 1fr;
        max-height: none;
        max-width: none;
        grid-gap: 0.75rem;

        & video {
            height: 140px;
            width: 140px;
        }

        & img {
            height: 140px;
            width: 140px;
        }
    }

    @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 1fr;
        max-height: none;
        max-width: none;
        grid-gap: 0.5rem;

        & video {
            height: 100px;
            width: 100px;
        }

        & img {
            height: 100px;
            width: 100px;
        }
    }
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
`;

export const InstagramLogo = styled.div`
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 8px;

    svg {
        width: 64px;
        height: 64px;
        color: ${muiTheme.palette.common.black};

        @media only screen and (max-width: 600px) {
            color: ${muiTheme.palette.primary.main};
            width: 64px;
            height: 64px;
        }
    }

    &:hover {
        opacity: 50%;
        cursor: pointer;

        @media only screen and (max-width: 600px) {
            opacity: 100%;
        }
    }
`;

export const Title = styled.h1`
    font-weight: 500;
    font-size: 2.5em;
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    white-space: nowrap;
    margin: 0;
    color: ${muiTheme.palette.text.main};

    @media only screen and (max-width: 600px) {
        font-size: 1.5em;
    }
`;

export const InstagramHandle = styled.a`
    font-weight: 500;
    font-size: 1.25em;
    text-transform: lowercase;
    font-family: "Open Sans", sans-serif;
    white-space: nowrap;
    margin: 0;

    @media only screen and (max-width: 600px) {
        font-size: 1.25em;
    }
`;

export const InstagramContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
