import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as contactActions from "../../core/redux/actions";
import PropTypes from "prop-types";
import Navigation from "../../components/Navigation";
import navigationLinks from "../../core/config/navigationLinks";

function NavigationSection({ scrollTo, currentlyAtScrollItem }) {
    const dispatch = useDispatch();
    const [navigationMenuIsOpen, setNavigationMenuIsOpen] = useState(false);
    const { internalLinks, externalLinks } = navigationLinks;

    const toggleNavigationMenu = (isOpen) => {
        setNavigationMenuIsOpen(isOpen);
    };

    const onScrollToItemClick = (scrollToItem) => {
        scrollTo(scrollToItem);
        setNavigationMenuIsOpen(false);
    };

    const onContactClick = () => {
        dispatch(contactActions.openContactModal(null));
    };

    return (
        <Navigation
            currentlyAtScrollItem={currentlyAtScrollItem}
            onScrollToItemClick={(scrollToItem) => onScrollToItemClick(scrollToItem)}
            internalLinks={internalLinks}
            externalLinks={externalLinks}
            navigationMenuIsOpen={navigationMenuIsOpen}
            toggleNavigationMenu={(isOpen) => toggleNavigationMenu(isOpen)}
            onContactClick={onContactClick}
        />
    );
}

NavigationSection.propTypes = {
    scrollTo: PropTypes.func,
    currentlyAtScrollItem: PropTypes.string,
};

export default NavigationSection;
