import React from "react";
import PropTypes from "prop-types";
import { ClassNames } from "@emotion/core";
import {
    MdEdit,
    MdSchool,
    MdColorLens,
    MdInsertDriveFile,
    MdDescription,
} from "react-icons/md";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import theme from "../../core/theme/muiTheme";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { parseParagraphs } from "../../utils/parseParagraphs";

function TimelineItem({ item, yearIndex, itemIndex }) {
    const { t } = useTranslation("timeline");
    const { type, date, title, location, description } = item;

    const getIcon = (timelineItemType) => {
        switch (timelineItemType) {
            case "project":
                return <MdEdit />;
            case "exhibition":
                return <MdColorLens />;
            case "education":
                return <MdSchool />;
            case "publication":
                return <MdDescription />;
            default:
                return <MdInsertDriveFile />;
        }
    };

    const getTypeColor = (timelineItemType) => {
        switch (timelineItemType) {
            case "project":
                return theme.palette.timelineType.project;
            case "exhibition":
                return theme.palette.timelineType.exhibition;
            case "education":
                return theme.palette.timelineType.education;
            case "publication":
                return theme.palette.timelineType.publication;
            default:
                return "black";
        }
    };

    const isEven = (number) => {
        if (number % 2 === 0) {
            return true;
        }

        return false;
    };

    const Type = styled.h1`
        ${type === "project" && `color: ${getTypeColor(type)}`};
        ${type === "exhibition" && `color: ${getTypeColor(type)}`};
        ${type === "education" && `color: ${getTypeColor(type)}`};
        ${type === "publication" && `color: ${getTypeColor(type)}`};
        ${theme.typography.typeHeading};
    `;

    const Title = styled.h1`
        color: ${theme.palette.text.main};
        ${theme.typography.heading};
    `;

    const Location = styled.h2`
        color: ${theme.palette.text.secondary} !important;
        ${theme.typography.subheading};
    `;

    const Description = styled.p`
        color: ${theme.palette.text.paragraph};
        ${theme.typography.paragraph};
    `;

    return (
        <ClassNames>
            {({ css }) => (
                <VerticalTimelineElement
                    aria-label={`${title}-${date}-timelineItem`}
                    contentStyle={{
                        background: theme.palette.common.white,
                        color: theme.palette.common.white,
                    }}
                    contentArrowStyle={{
                        borderRight: `7px solid ${theme.palette.common.white}`,
                    }}
                    date={date}
                    dateClassName={css({
                        color: theme.palette.common.black,
                        fontFamily: "Open Sans, sans-serif",
                    })}
                    iconStyle={{
                        background: getTypeColor(type),
                        color: theme.palette.common.white,
                    }}
                    icon={getIcon(type)}
                    position={
                        (isEven(yearIndex) ? !isEven(itemIndex) : isEven(itemIndex))
                            ? "right"
                            : "left"
                    }
                    visible={false}
                >
                    <Type>{t(type)}</Type>
                    <Title>{title}</Title>
                    <Location>{location}</Location>
                    {description &&
                        parseParagraphs(description).map((paragraph) => (
                            <Description key={paragraph[(0, 10)]}>
                                {paragraph}
                            </Description>
                        ))}
                </VerticalTimelineElement>
            )}
        </ClassNames>
    );
}

TimelineItem.propTypes = {
    item: PropTypes.shape({
        type: PropTypes.oneOf(["exhibition", "project", "education", "publication"]),
        date: PropTypes.string,
        icon: PropTypes.element,
        title: PropTypes.string,
        location: PropTypes.string,
        description: PropTypes.string,
    }),
    yearIndex: PropTypes.number,
    itemIndex: PropTypes.number,
};

export default TimelineItem;
