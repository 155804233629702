import React, { useState } from "react";
import Slider from "../../components/Slider";
import heroContent from "../../core/config/heroContent.json";

function HeroSection() {
    const [slideState, setSlideState] = useState({
        oldSlide: 0,
        activeSlide: 0,
    });

    return (
        <Slider
            activeSlide={slideState["activeSlide"]}
            beforeChangeSlide={(current, next) =>
                setSlideState({
                    oldSlide: current,
                    activeSlide: next,
                })
            }
            images={heroContent}
        />
    );
}

export default HeroSection;
