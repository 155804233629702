import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Gallery from "../../components/Gallery";
import LightboxModal from "../../components/LightboxModal";
import images from "../../core/config/galleryImages";
import * as contactActions from "../../core/redux/actions";

function GallerySection() {
    const { t } = useTranslation("gallery");
    const dispatch = useDispatch();
    const [lightboxPhotoIndex, setLightboxPhotoIndex] = useState(0);
    const [lightBoxIsOpen, setLightBoxIsOpen] = useState(false);

    const openInLightbox = (photoIndex) => {
        setLightboxPhotoIndex(photoIndex);
        setLightBoxIsOpen(true);
    };

    const closeLightbox = () => {
        setLightboxPhotoIndex(0);
        setLightBoxIsOpen(false);
    };

    const onContactAboutClick = () => {
        const subjectTitle = images[lightboxPhotoIndex]?.title;

        dispatch(
            contactActions.openContactModal(t("about_x", { subject: subjectTitle }))
        );
    };

    return (
        <React.Fragment>
            <LightboxModal
                images={images}
                lightBoxIsOpen={lightBoxIsOpen}
                lightboxPhotoIndex={lightboxPhotoIndex}
                openInLightbox={(photoIndex) => openInLightbox(photoIndex)}
                closeLightbox={() => closeLightbox()}
                onContactAboutClick={onContactAboutClick}
            />
            <Gallery
                images={images}
                openInLightbox={(photoIndex) => openInLightbox(photoIndex)}
            />
        </React.Fragment>
    );
}

export default GallerySection;
