import styled from "@emotion/styled";
import themeSnippets from "../../core/theme/themeSnippets";
import muiTheme from "../../core/theme/muiTheme";

export const Root = styled.div`
    position: relative;
`;

export const TextWrapper = styled.div`
    display: flex;
    width: 100%;
    height: calc(100vh - 52px);
    margin-top: -130px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const Text = styled.p`
    font-size: 7vh;
    padding-left: -32px;
    font-family: "Open Sans", sans-serif;
    margin: 0;
`;

export const Link = styled.a`
    font-size: 3.5vh;
    padding-left: -32px;
    font-family: "Open Sans", sans-serif;
    margin: 0;
`;

export const Content = styled.div`
    width: 100vw;
    height: 100vh;
    padding: 80px 32px;
    z-index: 3;

    @media only screen and (max-width: 1100px) {
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media only screen and (max-width: 600px) {
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const FaceBackgroundImage = styled.img`
    position: absolute;
    z-index: -1;
    left: 0%;
    bottom: 200px;
    height: 100vh;
    width: auto;

    @media only screen and (max-width: 1100px) {
        bottom: 40px;
        opacity: 33%;
    }

    @media only screen and (max-width: 600px) {
        bottom: 40px;
        opacity: 33%;
    }
`;

export const Slide = styled.div`
    ${themeSnippets.contentWrapper};
`;

export const Header = styled.div`
    display: flex;
    align-items: center;

    @media only screen and (max-width: 1100px) {
        align-items: start;
    }

    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
`;

export const Title = styled.h1`
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 2.5em;
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    white-space: nowrap;
    margin: 0;
    color: ${muiTheme.palette.text.main};
`;
