import muiTheme from "../../core/theme/muiTheme";
import styled from "@emotion/styled";

export const Root = styled.div`
    ${({ backgroundImage }) => `
        background-image: url(${backgroundImage});
    `};

    background-repeat: no-repeat, repeat;
    background-size: cover;
    z-index: 22;
`;

export const Content = styled.div`
    width: 100vw;
    height: 100vh;

    @media only screen and (max-width: 1100px) {
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media only screen and (max-width: 600px) {
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const BackgroundImage = styled.img`
    width: auto;
    height: 100vh;
    position: absolute;
    right: 0;
    z-index: -1;
`;

export const Slide = styled.div`
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media only screen and (max-width: 600px) {
        margin-top: -16px;
    }
`;

export const ContentWrapper = styled.div`
    background-color: rgba(255, 255, 255, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 48px;
    border-radius: 8px;
    max-width: 25vw;

    @media only screen and (max-width: 600px) {
        padding: 16px;
        max-width: 75vw;
    }
`;

export const Title = styled.h2`
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 1.25em;
    font-family: "Open Sans", sans-serif;
    white-space: nowrap;
    margin: 0;
    color: ${muiTheme.palette.text.secondary};

    @media only screen and (max-width: 600px) {
        font-size: 1em;
    }
`;

export const Name = styled.h1`
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 3.5vh;
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    white-space: nowrap;
    margin: 0;
    margin-bottom: 8;
    color: ${muiTheme.palette.text.main};

    @media only screen and (max-width: 600px) {
        font-size: 2.75vh;
    }
`;

export const StatementParagraph = styled.p`
    font-family: "Open Sans", sans-serif;
    text-align: left;
    font-size: 1.05em;
    color: ${muiTheme.palette.text.main};
    max-width: 48vw;

    @media only screen and (max-width: 1100px) {
        max-width: none;
    }

    @media only screen and (max-width: 600px) {
        font-size: 0.85em;
        padding: 0;
        max-width: 100vw;
        margin: 8px 0px;
        text-align: center;
    }
`;
