const images = [
    {
        src: require("../../assets/artworks/De Heilige Maagd.jpg"),
        title: "De Heilige Maagd",
        date: "2023",
        description: "Pastelkrijt op papier.",
        dimensions: "150cm x 200cm",
    },
    {
        src: require("../../assets/artworks/Diepte.jpg"),
        title: "Diepte",
        date: "November 2022",
        description: "Houtskool op papier.",
        dimensions: "150cm x 150cm",
    },
    {
        src: require("../../assets/artworks/Boterbloem.png"),
        title: "Boterbloem",
        date: "2022",
        description: "Pastelkrijt op papier.",
        dimensions: "110cm x 200cm",
    },
    {
        src: require("../../assets/artworks/Psyche.jpg"),
        title: "Psyche",
        date: "2021",
        description: "Pastelkrijt op papier.",
        dimensions: "150cm x 150cm",
    },
    {
        src: require("../../assets/artworks/Incarnatie.png"),
        title: "Incarnatie",
        date: "2021",
        description: "Pastelkrijt op papier.",
        dimensions: "150cm x 270cm",
    },
    {
        src: require("../../assets/artworks/Symbiose.jpg"),
        title: "Symbiose",
        date: "2021",
        dimensions: "200cm x 420cm",
        description: "Houtskool en pastelkrijt op papier.",
    },
    {
        src: require("../../assets/artworks/Hades geheel.jpg"),
        title: "Hades",
        date: "2020",
        dimensions: "200cm x 200cm",
        description: "Houtskool op papier.",
    },
    {
        src: require("../../assets/artworks/artwork_1.png"),
        title: "Untitled (1)",
        date: "2020",
        description: "",
    },
    {
        src: require("../../assets/artworks/artwork_2.png"),
        title: "Untitled (2)",
        date: "2020",
        description: "",
    },
    {
        src: require("../../assets/artworks/artwork_3.png"),
        title: "Untitled (3)",
        date: "2020",
        description: "",
    },
    {
        src: require("../../assets/artworks/artwork_4.png"),
        title: "Flow 2",
        date: "2016",
        dimensions: "280cm x 150cm",
        description: "Charcoal on paper.",
    },
    {
        src: require("../../assets/artworks/artwork_5.png"),
        title: "Untitled (4)",
        date: "2020",
        description: "",
    },
    {
        src: require("../../assets/artworks/artwork_6.png"),
        title: "Flow 3",
        date: "2016",
        dimensions: "270cm x 150cm",
        description: "Charcoal and graphite on paper.",
    },
    {
        src: require("../../assets/artworks/artwork_7.png"),
        title: "Untitled (5)",
        date: "2020",
        description: "",
    },
    {
        src: require("../../assets/artworks/artwork_8.png"),
        title: "Untitled (6)",
        date: "2020",
        description: "",
    },
    {
        src: require("../../assets/artworks/artwork_9.png"),
        title: "Untitled (7)",
        date: "2018",
        dimensions: "(3x) 50cm x 65cm",
        description: "Charcoal and pastel on paper.",
    },
    {
        src: require("../../assets/artworks/artwork_10.png"),
        title: "Flow 1",
        date: "2016",
        dimensions: "150cm x 290cm",
        description: "Charcoal and graphite on paper.",
    },
    {
        src: require("../../assets/artworks/artwork_11.jpg"),
        title: "Cybele",
        date: "2015",
        dimensions: "224cm high",
        description: "Plaster.",
    },
    {
        src: require("../../assets/artworks/artwork_12.jpg"),
        title: "De schepping van Eva",
        date: "2018",
        dimensions: "50cm x 65cm",
        description: "Colour pencil on paper.",
    },
    {
        src: require("../../assets/artworks/artwork_13.jpg"),
        title: "Eros & Thanatos",
        date: "2017",
        dimensions: "(2x) 500cm x 200cm",
        description: "",
    },
    {
        src: require("../../assets/artworks/artwork_14.jpg"),
        title: "Fairytale",
        date: "2018",
        dimensions: "275cm x 150cm",
        description: "Charcoal on paper",
    },
    {
        src: require("../../assets/artworks/artwork_15.png"),
        title: "Untitled (9)",
        date: "2018",
        dimensions: "(3x) 200cm x 100cm",
        description: "Charcoal on paper.",
    },
    {
        src: require("../../assets/artworks/artwork_16.jpg"),
        title: "Flap",
        date: "2015",
        dimensions: "24.5cm x 19cm",
        description: "Fineline on paper.",
    },
    {
        src: require("../../assets/artworks/artwork_17.jpg"),
        title: "Untitled (10)",
        date: "2017",
        dimensions: "32cm x 50cm",
        description: "Colour pencil on paper",
    },
    {
        src: require("../../assets/artworks/artwork_18.jpg"),
        title: "Lucid",
        date: "2017",
        dimensions: "(2x) 390cm x 150cm",
        description: "",
    },
    {
        src: require("../../assets/artworks/artwork_19.jpg"),
        title: "Mors Atra",
        date: "2014",
        dimensions: "Cotton figure in human size",
        description: "Installation",
    },
    {
        src: require("../../assets/artworks/artwork_20.jpg"),
        title: "Pelen 1",
        date: "2014",
        dimensions: "30cm x 21cm",
        description: "Colour pencil on paper.",
    },
    {
        src: require("../../assets/artworks/artwork_21.jpg"),
        title: "Pelen 2",
        date: "2014",
        dimensions: "30cm x 21cm",
        description: "Colour pencil on paper.",
    },
    {
        src: require("../../assets/artworks/artwork_22.jpg"),
        title: "Pelen 3",
        date: "2014",
        dimensions: "30cm x 21cm",
        description: "Colour pencil on paper.",
    },
    {
        src: require("../../assets/artworks/artwork_23.jpg"),
        title: "Wasp",
        date: "2016",
        dimensions: "70cm x 100cm",
        description: "Lithography",
    },
    {
        src: require("../../assets/artworks/artwork_24.jpg"),
        title: "Untitled (11)",
        date: "2017",
        dimensions: "83cm x 150cm",
        description: "Charcoal on paper",
    },
];

export default images;
