import { OPEN_CONTACT_MODAL, CLOSE_CONTACT_MODAL } from "./actionTypes";

export const openContactModal = (subject) => ({
    type: OPEN_CONTACT_MODAL,
    payload: subject,
});

export const closeContactModal = () => ({
    type: CLOSE_CONTACT_MODAL,
    payload: {},
});
