export default {
    heading: `
            font-family: "Open Sans", sans-serif;
            font-size: 30px;
            font-weight: 300;
            margin-top: 4px;
            margin-bottom: 0px;
            @media only screen and (max-width: 600px) {
                font-size: 20px;
            }
        `,
    subheading: `
            font-family: "Open Sans", sans-serif;
            font-size: 16px;
            font-weight: 500;
            margin-top: 0px;
            margin-bottom: 16px;
            @media only screen and (max-width: 600px) {
                margin-bottom: 4px;
            }
        `,
    paragraph: `
            font-family: "Open Sans", sans-serif;
            font-size: 15px;
            margin-top: 16px !important;
            margin-bottom: 16px !important;
            @media only screen and (max-width: 600px) {
                margin-top: 8px !important;
                margin-bottom: 8px !important;
            }
        `,
    typeHeading: `
            font-family: "Open Sans", sans-serif;
            text-transform: uppercase;
            font-size: 15px;
            font-weight: 500;
            margin-top: 4px;
            margin-bottom: -4px;
        `,
};
