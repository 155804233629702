import theme from "./muiTheme";

export default {
    heroContentWrapper: `
            padding-top: 64px;
            padding-bottom: 48px;
            padding-left: 10vw;
            padding-right: 10vw;

            @media only screen and (max-width: 600px) {
                padding-top: 48px;
                padding-bottom: 48px;
                padding-left: 7.5vw;
                padding-right: 7.5vw;
            }
    `,
    contentWrapper: `
            padding-top: 48px;
            padding-bottom: 48px;
            padding-left: 10vw;
            padding-right: 10vw;

            @media only screen and (max-width: 600px) {
                padding-top: 48px;
                padding-bottom: 48px;
                padding-left: 7.5vw;
                padding-right: 7.5vw;
            }
        `,
    hoverArrow: `
            position: absolute;
            z-index: 2;
            margin: 8px;
            height: 50px;
            width: 50px;
            background: ${theme.palette.common.hoverGrey};
            opacity: 0.25;
            transition: 0.3s;
            border-radius: 50px;

            &:hover {
                opacity: 1;
                cursor: pointer;

                @media only screen and (max-width: 600px) {
                    opacity: 0.25;
                    cursor: pointer;
                }
            }

            @keyframes hover {
                from {
                    background-color: ${theme.palette.common.invisibleGrey};
                }
                to {
                    background-color: ${theme.palette.common.hoverGrey};
                }
            }

            svg {
                height: 50px;
                width: 50px;
                color: ${theme.palette.common.white};
            }
    `,
};
