import React from "react";
import PropTypes from "prop-types";
import { VerticalTimeline } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import theme from "../../core/theme/muiTheme";
import styled from "@emotion/styled";
import TimelineItem from "../TimelineItem";
import themeSnippets from "../../core/theme/themeSnippets";

function Timeline({ timelineItems }) {
    if (!timelineItems) {
        return null;
    }

    const Background = styled.div`
        background-color: ${theme.palette.background.alt};
        padding-top: 16;
        ${themeSnippets.contentWrapper};
    `;

    const YearHeader = styled.h1`
        @media (max-width: 1170px) {
            text-align: left;
            margin-top: 8px;
            margin-left: 8px;
            font-size: 40px;
            font-size: 32px;
        }
        color: ${theme.palette.text.main};
        font-family: "Open Sans", sans-serif;
        font-size: 50px;
        font-weight: 400;
        text-align: center;
        margin-top: 16px;
        margin-bottom: 8px;
    `;

    return (
        <Background>
            {timelineItems.map((mainTimeline, yearIndex) => {
                const { year, items } = mainTimeline;

                return (
                    <div key={`[${yearIndex}]-${year}`}>
                        <YearHeader>{year}</YearHeader>
                        <VerticalTimeline animate={false}>
                            {items.map((item, itemIndex) => {
                                const { title, date } = item;

                                return (
                                    <TimelineItem
                                        key={`${title} ${date}`}
                                        item={item}
                                        yearIndex={yearIndex}
                                        itemIndex={itemIndex}
                                    />
                                );
                            })}
                        </VerticalTimeline>
                    </div>
                );
            })}
        </Background>
    );
}

Timeline.propTypes = {
    timelineItems: PropTypes.arrayOf(
        PropTypes.shape({
            year: PropTypes.number,
            timelineItems: PropTypes.arrayOf(
                PropTypes.shape({
                    type: PropTypes.oneOf([
                        "exhibition",
                        "project",
                        "education",
                        "publication",
                    ]),
                    date: PropTypes.string,
                    icon: PropTypes.element,
                    title: PropTypes.string,
                    location: PropTypes.string,
                    description: PropTypes.string,
                })
            ),
        })
    ),
};

export default Timeline;
