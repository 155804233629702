import React from "react";
import PropTypes from "prop-types";
import { HiOutlineMail } from "react-icons/hi";
import { SiGmail, SiMicrosoftoutlook } from "react-icons/si";
import { ImYahoo } from "react-icons/im";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Button,
    Typography,
} from "@material-ui/core";
import styled from "@emotion/styled";

function ContactModal({ subject, open, onClose }) {
    const { t } = useTranslation("contact");

    const renderContactInfo = () => {
        const EmailButtons = styled.div`
            margin: 16px 0px;
            width: 100%;
            display: grid;
            grid-gap: 8px;
            grid-template-columns: ${isMobile ? "1fr" : "1fr 1fr"};
        `;

        const contactEmail = "floor_christa@hotmail.com";

        return (
            <Dialog open={open} onClose={onClose} aria-label="contact-modal">
                <DialogTitle>{t("title")}</DialogTitle>
                <DialogContent dividers>
                    <Typography></Typography>
                    <Typography>
                        {t("contact_explain")}
                        <a
                            href={`mailto:${contactEmail}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {contactEmail}
                        </a>
                    </Typography>
                    <EmailButtons>
                        <Button
                            href={`mailto:${contactEmail}${
                                subject ? `?subject=${subject}` : ""
                            }`}
                            target="_blank"
                            rel="noopener noreferrer"
                            startIcon={<HiOutlineMail />}
                            variant="outlined"
                        >
                            {t("mail_client")}
                        </Button>
                        <Button
                            href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${contactEmail}${
                                subject ? `&su=${subject}` : ""
                            }`}
                            target="_blank"
                            rel="noopener noreferrer"
                            startIcon={<SiGmail />}
                            variant="outlined"
                        >
                            Gmail
                        </Button>
                        <Button
                            href={`https://outlook.live.com/owa/#${
                                subject ? `subject=${subject}&` : ""
                            }to=${contactEmail}&path=%2fmail%2faction%2fcompose`}
                            target="_blank"
                            rel="noopener noreferrer"
                            startIcon={<SiMicrosoftoutlook />}
                            variant="outlined"
                        >
                            Outlook
                        </Button>
                        <Button
                            href={`http://compose.mail.yahoo.com/?to=${contactEmail}${
                                subject ? `&subj=${subject}` : ""
                            }`}
                            target="_blank"
                            rel="noopener noreferrer"
                            startIcon={<ImYahoo />}
                            variant="outlined"
                        >
                            Yahoo
                        </Button>
                    </EmailButtons>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>{t("close")}</Button>
                </DialogActions>
            </Dialog>
        );
    };

    return renderContactInfo();
}

ContactModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    subject: PropTypes.string,
};

export default ContactModal;
