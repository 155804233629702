import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import nl from "./locales/nl";
import en from "./locales/en";

i18n.use(initReactI18next).init({
    lng: "nl",
    ns: Object.keys(nl),
    defaultNS: "common",
    resources: {
        nl,
        en,
    },
});

export default i18n;
