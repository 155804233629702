import React from "react";
import { useDispatch } from "react-redux";
import * as contactActions from "../../core/redux/actions";
import PropTypes from "prop-types";
import Footer from "../../components/Footer";
import footerLinks from "../../core/config/navigationLinks";

function FooterSection({ scrollTo }) {
    const dispatch = useDispatch();
    const { socialLinks, internalLinks, externalLinks } = footerLinks;

    const onContactClick = () => {
        dispatch(contactActions.openContactModal(null));
    };

    return (
        <Footer
            scrollTo={scrollTo}
            socialLinks={socialLinks}
            internalLinks={internalLinks}
            externalLinks={externalLinks}
            onContactClick={onContactClick}
        />
    );
}

FooterSection.propTypes = {
    scrollTo: PropTypes.func,
};

export default FooterSection;
