import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import themeSnippets from "../../core/theme/themeSnippets";
import { isMobile } from "react-device-detect";
import styled from "@emotion/styled";

function Gallery({ images, openInLightbox }) {
    const { t } = useTranslation("gallery");

    if (!images) {
        return <Typography>{t("no_images")}</Typography>;
    }

    const renderGallery = (images, columns) => {
        const Row = styled.div`
            display: flex;
            justify-content: center;
            max-width: 100%;
            flex-wrap: wrap;
            ${themeSnippets.contentWrapper};
            padding-top: 64px;
        `;

        const Column = styled.div`
            flex: ${100 / columns - 5}%;
            max-width: 40%;
            padding: 0 4px;

            @media only screen and (max-width: 600px) {
                max-width: 100%;
            }
        `;

        const Image = styled.img`
            margin-bottom: 64px;
            vertical-align: middle;
            width: 100%;
            cursor: pointer;
            &:hover {
                filter: brightness(75%);
            }
        `;

        const imageLimitColumn = images.length / columns;
        const hasImages = images.length > 0;
        let columnIndex = -1;
        const isFirstColumn = columnIndex === 0;

        const renderColumn = (columnIndex) => {
            const renderImage = (
                columnIndex,
                itemIndex,
                imageLimitColumn,
                src,
                title
            ) => {
                const photoIndex =
                    columnIndex === 0
                        ? itemIndex
                        : itemIndex + Math.floor(imageLimitColumn) * columnIndex;

                return (
                    <Image
                        key={title}
                        src={src}
                        onClick={() => openInLightbox(photoIndex)}
                    />
                );
            };

            if (columnIndex === 0) {
                return images
                    .slice(0, imageLimitColumn)
                    .map(({ src, title }, itemIndex) => {
                        return renderImage(
                            columnIndex,
                            itemIndex,
                            imageLimitColumn,
                            src,
                            title
                        );
                    });
            }

            return images
                .slice(
                    columnIndex * imageLimitColumn,
                    imageLimitColumn * (columnIndex + 1)
                )
                .map(({ src, title }, itemIndex) => {
                    return renderImage(
                        columnIndex,
                        itemIndex,
                        imageLimitColumn,
                        src,
                        title
                    );
                });
        };

        return (
            <div>
                <Row>
                    {Array.from({ length: columns }, () => {
                        columnIndex++;
                        return (
                            <Column key={columnIndex}>
                                {hasImages > 0 && isFirstColumn
                                    ? renderColumn(0)
                                    : renderColumn(columnIndex)}
                            </Column>
                        );
                    })}
                </Row>
            </div>
        );
    };

    return <div>{renderGallery(images, !isMobile ? 1 : 1)}</div>;
}

Gallery.propTypes = {
    images: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string,
            title: PropTypes.string,
            date: PropTypes.string,
            description: PropTypes.string,
        })
    ),
    openInLightbox: PropTypes.func,
};

export default Gallery;
