import React from "react";
import PropTypes from "prop-types";
import { MdMenu } from "react-icons/md";
import styled from "@emotion/styled";
import Divider from "@material-ui/core/Divider";
import {
    SwipeableDrawer,
    List,
    ListItem,
    ListItemText,
    IconButton,
    ListItemIcon,
    Button,
} from "@material-ui/core";
import { isMobile } from "react-device-detect";
import muiTheme from "../../core/theme/muiTheme";

function Navigation({
    currentlyAtScrollItem,
    onScrollToItemClick,
    internalLinks,
    externalLinks,
    navigationMenuIsOpen,
    toggleNavigationMenu,
    onContactClick,
}) {
    const Logo = styled.div`
        padding: 16px;
        font-weight: 500;
        letter-spacing: 2px;
        font-family: "Open Sans", sans-serif;
        white-space: nowrap;
    `;

    const renderNavigationBar = () => {
        if (isMobile) {
            const NavBarMobile = styled.div`
                position: fixed;
                z-index: 2;
                width: 100%;
                padding: 4px 10px;
            `;

            return (
                <NavBarMobile>
                    <IconButton
                        onClick={() => toggleNavigationMenu(true)}
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                    >
                        <MdMenu />
                    </IconButton>
                </NavBarMobile>
            );
        }

        const NavBar = styled.div`
            position: fixed;
            display: flex;
            width: 100%;
            justify-content: center;
            z-index: 2;
            background: ${muiTheme.palette.common.white};
            box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.4);
        `;

        const ActionSection = styled.div`
            display: flex;
            width: 100%;
            justify-content: space-between;
        `;

        const StyledList = styled(List)`
            display: flex;
            padding: 0px;
        `;

        const ContactButton = styled.button`
            background-color: ${muiTheme.palette.primary.main};
            color: ${muiTheme.palette.common.black};
            border-radius: 0px;
            border: none;
            padding-left: 16px;
            padding-right: 16px;
            font-weight: 500;
            text-transform: uppercase;
            font-size: 1em;
            &:hover {
                filter: brightness(75%);
                cursor: pointer;
            }
            &:active {
                outline: none;
                border: none;
            }
            &:focus {
                filter: brightness(75%);
                outline: none;
                border: none;
            }
        `;

        return (
            <NavBar>
                <Logo>Floor Schothorst</Logo>
                <ActionSection>
                    <StyledList>
                        {internalLinks &&
                            internalLinks.length >= 1 &&
                            internalLinks.map((link) =>
                                renderMenuItem("internal", link)
                            )}
                    </StyledList>
                    <ContactButton onClick={() => onContactClick()}>
                        Contact
                    </ContactButton>
                </ActionSection>
            </NavBar>
        );
    };

    const renderMobileMenu = () => {
        return (
            <SwipeableDrawer
                anchor={"left"}
                open={navigationMenuIsOpen}
                onClose={() => toggleNavigationMenu(false)}
                onOpen={() => toggleNavigationMenu(true)}
            >
                <Logo>Floor Schothorst</Logo>
                <Divider />
                <List>
                    {internalLinks &&
                        internalLinks.length >= 1 &&
                        internalLinks.map((link) =>
                            renderMenuItem("internal", link)
                        )}
                </List>
                {externalLinks && externalLinks.length >= 1 && (
                    <React.Fragment>
                        <Divider />
                        <List>
                            {externalLinks.map((link) =>
                                renderMenuItem("external", link)
                            )}
                        </List>
                    </React.Fragment>
                )}
                <Divider />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onContactClick()}
                    style={{ margin: "8px" }}
                >
                    Contact
                </Button>
            </SwipeableDrawer>
        );
    };

    const renderMenuItem = (type, item) => {
        if (type === "external") {
            const { text, to, Icon, color } = item;

            return (
                <ListItem
                    key={text}
                    onClick={(event) => {
                        event.preventDefault();
                        window.open(to, "_blank");
                    }}
                    button
                >
                    {Icon ? (
                        <ListItemIcon>
                            <Icon style={{ color: color }} />
                        </ListItemIcon>
                    ) : null}
                    <ListItemText primary={text} />
                </ListItem>
            );
        }

        if (type === "internal") {
            const { text, scrollToRef, Icon, color } = item;

            return (
                <ListItem
                    key={text}
                    selected={currentlyAtScrollItem === scrollToRef}
                    onClick={() => onScrollToItemClick(scrollToRef)}
                    button
                >
                    {Icon ? (
                        <ListItemIcon>
                            <Icon style={{ color: color }} />
                        </ListItemIcon>
                    ) : null}
                    <ListItemText primary={text} />
                </ListItem>
            );
        }

        return;
    };

    return (
        <div>
            {renderNavigationBar()}
            {renderMobileMenu()}
        </div>
    );
}

Navigation.propTypes = {
    onScrollToItemClick: PropTypes.func,
    internalLinks: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            scrollToRef: PropTypes.string,
            Icon: PropTypes.func,
            color: PropTypes.string,
        })
    ),
    externalLinks: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            to: PropTypes.string,
            Icon: PropTypes.func,
            color: PropTypes.string,
        })
    ),
    socialLinks: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.oneOf(["facebook", "twitter", "pinterest", "instagram"]),
            text: PropTypes.string,
            to: PropTypes.string,
            Icon: PropTypes.func,
        })
    ),
    navigationMenuIsOpen: PropTypes.bool,
    toggleNavigationMenu: PropTypes.func,
    onContactClick: PropTypes.func,
};

export default Navigation;
