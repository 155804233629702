import { createMuiTheme } from "@material-ui/core/styles";
import typography from "./typography";

export default createMuiTheme({
    overrides: {
        MuiListItemIcon: {
            root: {
                minWidth: "36px",
                color: "#FFCE54",
                "& svg": {
                    width: "1.25em",
                    height: "1.25em",
                },
            },
        },
    },
    palette: {
        primary: {
            main: "#FFCE54",
        },
        secondary: {
            main: "#F6BB42",
        },
        common: {
            white: "#fff",
            transparentWhite: "RGBA(255,255,255,0.5)",
            black: "#242423",
            transparentGrey: "RGBA(36,36,35,0.15)",
            hoverGrey: "RGBA(36,36,35,0.5)",
            invisibleGrey: "RGBA(36,36,35,0)",
        },
        text: {
            main: "#242423",
            secondary: "#586A6A",
            paragraph: "#586A6A",
        },
        background: {
            alt: "#E5E5E5",
        },
        timelineType: {
            project: "#FFCE54",
            exhibition: "#FFCE54",
            education: "#FFCE54",
            publication: "#FFCE54",
        },
    },
    typography,
});
