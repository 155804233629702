import { MdHome, MdPhotoLibrary, MdWork } from "react-icons/md";
import theme from "../theme/muiTheme";

const navigationLinks = {
    internalLinks: [
        {
            text: "Home",
            scrollToRef: "HeroSection",
            Icon: MdHome,
            color: theme.palette.timelineType.exhibition,
        },
        {
            text: "Galerij",
            scrollToRef: "GallerySection",
            Icon: MdPhotoLibrary,
            color: theme.palette.timelineType.project,
        },
        {
            text: "CV",
            scrollToRef: "TimelineSection",
            Icon: MdWork,
            color: theme.palette.timelineType.education,
        },
    ],
    externalLinks: [],
    socialLinks: [
        {
            type: "instagram",
            text: "@floorschothorst",
            to: process.env.REACT_APP_INSTAGRAM_LINK,
        },
    ],
};

export default navigationLinks;
