import React from "react";
import PropTypes from "prop-types";
import theme from "../../core/theme/muiTheme";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { RiInstagramFill } from "react-icons/ri";
import {
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography,
} from "@material-ui/core";

function Footer({
    scrollTo,
    internalLinks,
    externalLinks,
    socialLinks,
    onContactClick,
}) {
    const Root = styled.div`
        @media (max-width: 1170px) {
            padding: 24px 7.5vw;
        }
        background-color: ${theme.palette.common.white};
        padding: 32px 64px;
        display: grid;
        grid-template-columns: repeat(
            ${externalLinks && externalLinks.length >= 1 ? 3 : 2},
            1fr
        );
        grid-gap: 16px;
    `;

    const SocialButtonsContainer = styled.div`
        display: flex;
        justify-content: flex-end;
    `;

    const SocialButton = styled.div`
        color: ${theme.palette.common.black};
        & svg {
            height: 64px;
            width: 64px;
        }
        cursor: pointer;
        &:hover {
            opacity: 50%;
        }
    `;

    const { t } = useTranslation("footer");

    const renderLink = (type, link) => {
        const { text, scrollToRef, to } = link;
        const isInternalLink = type === "internal link";
        const isExternalLink = type === "external link";
        const { Icon, color } = link;

        return (
            <ListItem
                key={`${type}-${text}`}
                onClick={isInternalLink ? () => scrollTo(scrollToRef) : null}
                to={isExternalLink ? to : null}
                button
            >
                {Icon ? (
                    <ListItemIcon>
                        <Icon style={{ color: color }} />
                    </ListItemIcon>
                ) : null}
                <ListItemText primary={text} />
            </ListItem>
        );
    };

    const renderSocialButton = ({ type, text, to, icon }) => {
        if (type === "instagram") {
            return (
                <Tooltip key={`${type}-${text}-tooltip`} title={text} arrow>
                    <SocialButton
                        aria-label={`social-link-${type}-${text}`}
                        key={`${type}-${text}`}
                        onClick={(e) => {
                            e.preventDefault();
                            window.open(to, "_blank");
                        }}
                    >
                        {renderSocialIcon(type)}
                    </SocialButton>
                </Tooltip>
            );
        }

        return null;
    };

    const renderSocialIcon = (type) => {
        if (type === "instagram") {
            return <RiInstagramFill />;
        }

        return null;
    };

    return (
        <Root>
            <div>
                <Typography variant="h6">{t("navigation")}</Typography>
                {internalLinks && internalLinks.length >= 1 && (
                    <div aria-label="internal links">
                        <List component="nav" aria-label="internal links list">
                            {internalLinks.map((internalLink) =>
                                renderLink("internal link", internalLink)
                            )}
                        </List>
                    </div>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onContactClick()}
                >
                    Contact
                </Button>
            </div>
            {externalLinks && externalLinks.length >= 1 ? (
                <div>
                    <Typography variant="h6">{t("other_links")}</Typography>
                    {externalLinks && externalLinks.length >= 1 && (
                        <div aria-label="external links">
                            <List component="nav" aria-label="external links list">
                                {externalLinks.map((externalLink) =>
                                    renderLink("external link", externalLink)
                                )}
                            </List>
                        </div>
                    )}
                </div>
            ) : null}
            {socialLinks && socialLinks.length >= 1 && (
                <SocialButtonsContainer aria-label="socialLinks">
                    {socialLinks.map((link) => renderSocialButton(link))}
                </SocialButtonsContainer>
            )}
        </Root>
    );
}

Footer.propTypes = {
    elements: PropTypes.objectOf(PropTypes.object),
    internalLinks: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            scrollToRef: PropTypes.string,
            Icon: PropTypes.func,
            color: PropTypes.string,
        })
    ),
    externalLinks: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            to: PropTypes.string,
            Icon: PropTypes.func,
            color: PropTypes.string,
        })
    ),
    socialLinks: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.oneOf(["facebook", "twitter", "pinterest", "instagram"]),
            text: PropTypes.string,
            to: PropTypes.string,
            icon: PropTypes.string,
        })
    ),
    onContactClick: PropTypes.func,
};

export default Footer;
