import React from "react";
import { useSelector, useDispatch } from "react-redux";
import ContactModal from "../../components/ContactModal";
import * as contactActions from "../../core/redux/actions";

function ContactModalContainer() {
    const dispatch = useDispatch();
    const contactModalIsOpen = useSelector((state) => state.contact.contactIsOpen);
    const subject = useSelector((state) => state.contact.subject);

    const closeContactModal = () => {
        dispatch(contactActions.closeContactModal());
    };

    return (
        <ContactModal
            open={contactModalIsOpen}
            subject={subject}
            onClose={closeContactModal}
        />
    );
}

export default ContactModalContainer;
