import { OPEN_CONTACT_MODAL, CLOSE_CONTACT_MODAL } from "../actionTypes";

const initialState = {
    contactIsOpen: false,
    subject: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case OPEN_CONTACT_MODAL: {
            return {
                ...state,
                contactIsOpen: true,
                subject: action.payload,
            };
        }
        case CLOSE_CONTACT_MODAL: {
            return {
                ...state,
                contactIsOpen: false,
                subject: null,
            };
        }
        default:
            return state;
    }
}
