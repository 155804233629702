import React from "react";
import PropTypes from "prop-types";
import RSSlider from "react-slick";
import styled from "@emotion/styled";
import theme from "../../core/theme/muiTheme";
import themeSnippets from "../../core/theme/themeSnippets";
import {
    MdKeyboardArrowLeft,
    MdKeyboardArrowRight,
    MdLens,
    MdPanoramaFishEye,
} from "react-icons/md";
import "slick-carousel/slick/slick.css";
import Intro from "../../slides/Intro/Intro";
import Instagram from "../../slides/Instagram/Instagram";
import Etsy from "../../slides/Etsy/Etsy";

const SliderWrap = styled.div`
    .slick-slider {
        height: 100vh;
        .slick-list {
            .slick-track {
                .slick-slider.slide_item {
                    height: 100vh;
                    width: 100vw;
                    background-size: cover;
                    background-repeat: no-repeat;
                    ${themeSnippets.contentWrapper};
                }
            }
        }
    }
`;

const LeftArrow = styled.div`
    ${themeSnippets.hoverArrow};
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
`;

const RightArrow = styled.div`
    ${themeSnippets.hoverArrow};
    top: 50%;
    right: 0%;
    transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
`;

function PreviousArrow({ onClick }) {
    return (
        <LeftArrow onClick={onClick}>
            <MdKeyboardArrowLeft />
        </LeftArrow>
    );
}

function NextArrow({ onClick }) {
    return (
        <RightArrow onClick={onClick}>
            <MdKeyboardArrowRight />
        </RightArrow>
    );
}

const DotsList = styled.ul`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0%;
    list-style-type: none;
    width: 100%;
    padding: 16px 0px;
    margin: 0;
    background-color: ${theme.palette.common.black};
`;

const DotLink = styled.a`
    padding: 8px;
    color: ${theme.palette.common.white};
`;

function Slider({ activeSlide, beforeChangeSlide, images }) {
    const settings = {
        dots: true,
        accessibility: true,
        swipeToSlide: true,
        adaptiveHeight: true,
        autoplay: false,
        autoplaySpeed: 7500,
        speed: 750,
        slidesToShow: 1,
        slidesToScroll: 1,
        customPaging: function (i) {
            return (
                <DotLink>
                    {activeSlide === i ? <MdLens /> : <MdPanoramaFishEye />}
                </DotLink>
            );
        },
        appendDots: (dots) => (
            <div>
                <DotsList>{dots}</DotsList>
            </div>
        ),
        nextArrow: <NextArrow />,
        prevArrow: <PreviousArrow />,
        beforeChange: (current, next) => beforeChangeSlide(current, next),
    };

    const renderSlide = ({ title, description, imageSource, date, type }) => {
        return (
            <div key={`${type}-${title}-${date}`} width="100%">
                <img src={imageSource} alt={title} width="100%" />
                <div>
                    <div>
                        <h1>{title}</h1>
                        {date && <h1> - {date}</h1>}
                    </div>
                    {type && <h3>{type}</h3>}
                    <p>{description}</p>
                </div>
            </div>
        );
    };

    return (
        <SliderWrap>
            <RSSlider {...settings}>
                <Intro />
                <Etsy />
                <Instagram />
                {images?.map((slide) => renderSlide(slide))}
            </RSSlider>
        </SliderWrap>
    );
}

Slider.propTypes = {
    autoplay: PropTypes.bool,
    images: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            imageSource: PropTypes.string,
            date: PropTypes.string,
            type: PropTypes.string,
        })
    ),
    numberOfSlides: PropTypes.number,
    customDots: PropTypes.element,
    customNextArrow: PropTypes.element,
    customPreviousArrow: PropTypes.element,
};

export default Slider;
