import { MuiThemeProvider, StylesProvider } from "@material-ui/core";
import { ThemeProvider } from "emotion-theming";
import React from "react";
import { Provider } from "react-redux";
import store from "./core/redux/store.js";
import { I18nextProvider } from "react-i18next";
import HeroSection from "./containers/HeroSection";
import TimelineSection from "./containers/TimelineSection";
import i18n from "./core/i18n";
import theme from "./core/theme/muiTheme";
import FooterSection from "./containers/FooterSection/index.js";
import ScrollNavigation from "react-single-page-navigation";
import NavigationSection from "./containers/NavigationSection/index.js";
import GallerySection from "./containers/GallerySection/GallerySection.jsx";
import { Helmet } from "react-helmet";
import ContactModalContainer from "./containers/ContactModalContainer/ContactModalContainer.jsx";
import ReactGA from "react-ga4";

function App() {
    ReactGA.initialize("G-1SLDBJJTEB");
    ReactGA.send({ hitType: "pageview", page: "/", title: "Visited home page" });

    const elements = {
        HeroSection: {},
        GallerySection: {},
        TimelineSection: {},
    };

    const Section = ({ component: Component, scrollReference, ...rest }) => {
        return (
            <div ref={scrollReference}>
                <Component {...rest} />
            </div>
        );
    };

    return (
        <Provider store={store}>
            <StylesProvider injectFirst>
                <MuiThemeProvider theme={theme}>
                    <ThemeProvider theme={theme}>
                        <I18nextProvider i18n={i18n}>
                            <ScrollNavigation elements={elements}>
                                {({ refs, activeElement, goTo }) => {
                                    const sections = [
                                        {
                                            id: "HeroSection",
                                            component: HeroSection,
                                            scrollReference: refs.HeroSection,
                                        },
                                        {
                                            id: "GallerySection",
                                            component: GallerySection,
                                            scrollReference: refs.GallerySection,
                                        },
                                        {
                                            id: "TimelineSection",
                                            component: TimelineSection,
                                            scrollReference: refs.TimelineSection,
                                        },
                                    ];

                                    return (
                                        <div>
                                            <Helmet>
                                                <title>Floor Schothorst</title>
                                                <meta
                                                    name="Floor Schothorst"
                                                    content="One page website"
                                                />
                                                <link
                                                    rel="icon"
                                                    type="image/png"
                                                    href="favicon.ico"
                                                    sizes="16x16"
                                                />
                                            </Helmet>
                                            <ContactModalContainer />
                                            <NavigationSection
                                                scrollTo={(element) => goTo(element)}
                                                currentlyAtScrollItem={activeElement}
                                            />
                                            {sections.map(
                                                ({
                                                    id,
                                                    component,
                                                    scrollReference,
                                                }) => (
                                                    <Section
                                                        key={id}
                                                        scrollReference={
                                                            scrollReference
                                                        }
                                                        component={component}
                                                    />
                                                )
                                            )}
                                            <FooterSection
                                                scrollTo={(element) => goTo(element)}
                                            />
                                        </div>
                                    );
                                }}
                            </ScrollNavigation>
                        </I18nextProvider>
                    </ThemeProvider>
                </MuiThemeProvider>
            </StylesProvider>
        </Provider>
    );
}

export default App;
