import React from "react";
import { RiInstagramFill } from "react-icons/ri";
import { Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import instagramPreviews from "../../core/config/instagramPreviews";
import {
    PreviewContainer,
    Slide,
    InstagramHandle,
    InstagramLogo,
    Title,
    Root,
    Content,
    Header,
    InstagramContainer,
} from "./Instagram.styles";
import { isMobile } from "react-device-detect";

function Instagram() {
    const { t } = useTranslation("instagram");
    const instagramPreviewsArray = instagramPreviews.map((preview) => {
        if (preview.type === "video") {
            return (
                <video autoPlay loop controls>
                    <source src={preview.src} type="video/mp4" />
                    {t("video_tag_not_supported_error")}
                </video>
            );
        }

        if (preview.type === "image") {
            return <img src={preview.src} alt={preview.src} />;
        }

        return null;
    });

    const renderPreviews = () => {
        return (
            <PreviewContainer>
                {isMobile
                    ? instagramPreviewsArray.slice(0, 6)
                    : instagramPreviewsArray}
            </PreviewContainer>
        );
    };

    const renderContent = () => {
        const instagramUrl = process.env.REACT_APP_INSTAGRAM_LINK;

        return (
            <Slide>
                <InstagramContainer>
                    <Header>
                        <Tooltip title={instagramUrl} placement="top" arrow>
                            <InstagramLogo
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open(instagramUrl, "_blank");
                                }}
                            >
                                <RiInstagramFill />
                            </InstagramLogo>
                        </Tooltip>
                        <div>
                            <Title>{t("instagram")}</Title>
                            <InstagramHandle href={instagramUrl} target="_blank">
                                {t("instagram_handle")}
                            </InstagramHandle>
                        </div>
                    </Header>
                    {renderPreviews()}
                </InstagramContainer>
            </Slide>
        );
    };

    return (
        <Root>
            <Content>{renderContent()}</Content>
        </Root>
    );
}

export default Instagram;
