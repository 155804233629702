import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import styled from "@emotion/styled";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

function LightboxModal({
    images,
    lightBoxIsOpen,
    openInLightbox,
    closeLightbox,
    lightboxPhotoIndex,
    onContactAboutClick,
}) {
    const renderLightboxContactButton = () => {
        return (
            <Button
                aria-label="contact-about-button"
                onClick={() => onContactAboutClick()}
                variant={"contained"}
                color={"primary"}
            >
                Contact
            </Button>
        );
    };

    const renderLightboxDescription = ({ title, date, description, dimensions }) => {
        const InfoContainer = styled.div`
            margin-top: 8px;
            display: flex;
        `;

        const Info = styled.div`
            display: flex;
            flex-direction: column;
            margin-left: 8px;
            padding-left: 8px;
            border-left: 3px solid white;
            font-family: "Open Sans", sans-serif;
        `;

        const Date = styled.p`
            margin: 0px;
            margin-bottom: 1px;
            font-family: "Open Sans", sans-serif;
        `;

        const Price = styled.p`
            margin: 0px;
            margin-top: 1px;
            font-family: "Open Sans", sans-serif;
        `;

        const Description = styled.p`
            font-family: "Open Sans", sans-serif;
        `;

        return (
            <div>
                <ArtworkTitle>{title}</ArtworkTitle>
                <InfoContainer>
                    {renderLightboxContactButton()}
                    <Info>
                        <Date>{date}</Date>
                        <Price>{dimensions}</Price>
                    </Info>
                </InfoContainer>
                <Description>{description}</Description>
            </div>
        );
    };

    const Title = styled.p`
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        line-height: 1;
    `;

    const ArtworkTitle = styled.h1`
        display: inline;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        line-height: 1;
    `;

    return (
        lightBoxIsOpen && (
            <Lightbox
                mainSrc={images[lightboxPhotoIndex].src}
                nextSrc={images[(lightboxPhotoIndex + 1) % images.length].src}
                prevSrc={
                    images[(lightboxPhotoIndex + images.length - 1) % images.length]
                        .src
                }
                onCloseRequest={() => closeLightbox()}
                onMovePrevRequest={() =>
                    openInLightbox(
                        (lightboxPhotoIndex + images.length - 1) % images.length
                    )
                }
                onMoveNextRequest={() =>
                    openInLightbox(
                        (lightboxPhotoIndex + images.length + 1) % images.length
                    )
                }
                imageCaption={renderLightboxDescription(images[lightboxPhotoIndex])}
                imageTitle={<Title>{images[lightboxPhotoIndex].title}</Title>}
            />
        )
    );
}

LightboxModal.propTypes = {
    images: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string,
            title: PropTypes.string,
            date: PropTypes.string,
            description: PropTypes.string,
        })
    ),
    lightBoxIsOpen: PropTypes.bool,
    lightboxPhotoIndex: PropTypes.number,
    openInLightbox: PropTypes.func,
    closeLightbox: PropTypes.func,
    onContactAboutClick: PropTypes.func,
};

export default LightboxModal;
