export default {
    common: require("./common.json"),
    hero: require("./hero.json"),
    intro: require("./intro.json"),
    instagram: require("./instagram.json"),
    gallery: require("./gallery.json"),
    timeline: require("./timeline.json"),
    contact: require("./contact.json"),
    footer: require("./footer.json"),
};
